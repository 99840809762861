import React from 'react'
import { motion, Variants } from 'framer-motion'
import { useNewsCardTransition } from '../../../animation'
import { BodyText1, Heading5, Subtitle1 } from '../../../components/Heading'
import { ForwardIcon } from '../../../components/Icon'
import { Link } from '../../../components/Link'
// import { isBrowser } from '../../../utils'

import './index.scss'

export interface BrandCardProps {
  icon?: React.ReactNode | string
  name: string
  subtitle: string
  description: string
  external_link?: string
}

export const BrandLogo: React.FC<{ name: string }> = ({ name }) => {
  return <div className={`brand-logo ${name}`}></div>
}

export const BrandCard: React.FC<BrandCardProps> = ({
  icon,
  name,
  subtitle,
  description,
  external_link = '',
}) => {
  let Icon = icon
  if (typeof icon === 'string') {
    Icon = <img className="brand-logo" src={icon} alt={subtitle} />
  }

  const transition = useNewsCardTransition()
  const titleVariants: Variants = {
    initial: { y: 0, opacity: 0.8 },
    hover: { y: -8, opacity: 1 },
  }
  const arrowVariants: Variants = {
    initial: { rotate: 0, opacity: 0.5 },
    hover: { rotate: 45, opacity: 1 },
  }
  const children = (
    <>
      {Icon}
      <motion.div variants={titleVariants}>
        <Heading5>{name}</Heading5>
        <Subtitle1>{subtitle}</Subtitle1>
        <BodyText1>{description}</BodyText1>
      </motion.div>
      <motion.span variants={arrowVariants} className="forward-icon">
        <ForwardIcon />
      </motion.span>
    </>
  )

  return (
    <motion.div
      className="brand-card"
      initial="initial"
      whileHover="hover"
      transition={transition}>
      {external_link ? (
        <Link to={external_link} external={true}>
          {children}
        </Link>
      ) : (
        children
      )}
    </motion.div>
  )
}
